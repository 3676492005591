@import '../../mixins';

.cta {
  position: relative;
  padding: rem(60) 0;
  overflow: visible;
  background-color: var(--section-background-color);

  @include mediaBigDesktop {
    padding: big(60) 0;
  }

  @include media(#{rem(700)}) {
    padding: rem(50) 0 0;
  }
  
  &_text-light {
      --section-text-color-primary: var(--text-light-primary);
      --section-text-color-secondary: var(--text-light-secondary);
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: rem(20);
    row-gap: rem(34);

    @include mediaBigDesktop {
      column-gap: big(20);
      row-gap: big(34);
    }

    @include media(#{rem(1160)}) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media(#{rem(700)}) {
      display: flex;
      flex-direction: column;
      gap: rem(20);
    }
  }

  &__column {
    grid-column: 1/4;

    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: rem(15);

    @include media(#{rem(1160)}) {
      grid-column: 1/2;
      grid-row: 1/2;
    }
  }

  &__title {
    color: var(--section-text-color-primary);
  }

  &__text {
    color: var(--section-text-color-secondary);
  }

  &__form {
    grid-column: 5/9;

    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: rem(25);

    @include media(#{rem(1160)}) {
      grid-column: 1/2;
      grid-row: 2/3;
    }

    & .checkbox {
      & .text {
        color: var(--section-text-color-secondary);

        @include mediaLaptop {
          font-size: rem(12);
        }

        & a {
          position: relative;
          z-index: 1;

          color: var(--section-text-color-primary);
          text-decoration: underline;

          @include hover {
            color: var(--accent-color);
          }
        }
      }
    }
  }

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    gap: rem(20);

    @include mediaBigDesktop {
      gap: big(20);
    }

    @include media(#{rem(700)}) {
      gap: rem(15);
    }

    @include media(#{rem(400)}) {
      flex-direction: column;
    }

    & .text-input {
      width: 100%;
      flex-shrink: 1;

      & input {
        width: 100%;
      }
    }

    & .button {
      flex-shrink: 0;

      white-space: nowrap;

      @include media(#{rem(400)}) {
        width: 100%;
      }
    }
  }

  &__image {
    position: relative;
    grid-column: 9/13;

    display: flex;
    align-items: center;
    justify-content: center;

    pointer-events: none;
    user-select: none;

    @include media(#{rem(1160)}) {
      grid-column: 2/3;
      grid-row: 1/3;
    }

    @include media(#{rem(700)}) {
      height: fit-content;
    }

    & picture {
      position: absolute;
      bottom: rem(-60);
      left: rem(20);
      right: 0;
      margin: auto;

      width: auto;
      height: calc(100% + #{rem(120)} + #{rem(63)});

      @include mediaBigDesktop {
        bottom: big(-60);
        left: big(20);
        
        height: calc(100% + big(120) + big(63));
      }

      @include media(#{rem(1160)}) {
        left: 0;
        height: calc(100% + #{rem(120)} + #{rem(46)});
      }

      @include media(#{rem(700)}) {
        position: relative;
        bottom: 0;

        width: auto;
        height: rem(266);
      }

      & img {
        width: 100%;
        height: 100%;

        object-fit: contain;
        object-position: bottom;
      }
    }
  }

  &_second {
    padding: 0;

    @include media(#{rem(1070)}) {
      padding-top: rem(60);
    }

    @include mediaMobile {
      padding-top: rem(50);
    }

    & .cta {
      &__content {
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        justify-content: start;

        @include media(#{rem(1070)}) {
          display: flex;
          flex-direction: column;
          gap: rem(30);
        }

        @include mediaMobile {
          gap: rem(20);
        }
      }

      &__column {
        grid-column: 1/2;
      }

      &__image {
        grid-column: 2/3;

        width: 100%;

        & img,
        & picture {
          position: unset;
          width: 100%;
          height: rem(336);

          object-fit: contain;
          object-position: 50% 100%;

          @include mediaBigDesktop {
            height: big(336);
          }

          @include mediaLaptop {
            height: rem(289);
          }

          @include media(#{rem(1070)}) {
            width: 100%;
            max-width: rem(404);
            height: auto;
          }
        }
      }

      &__form {
        align-items: start;
        justify-content: start;
      }

      &__row {
        width: 100%;

        @include mediaMobile {
          flex-direction: column;
        }

        & .button {
          @include mediaMobile {
            width: 100%;
          }
        }
      }
    }
  }
}
