.cta {
  position: relative;
  padding: 3.75rem 0;
  overflow: visible;
  background-color: var(--section-background-color); }
  @media screen and (min-width: 160.0625rem) {
    .cta {
      padding: 2.34375vw 0; } }
  @media screen and (max-width: 43.75rem) {
    .cta {
      padding: 3.125rem 0 0; } }
  .cta_text-light {
    --section-text-color-primary: var(--text-light-primary);
    --section-text-color-secondary: var(--text-light-secondary); }
  .cta__content {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    -webkit-column-gap: 1.25rem;
       -moz-column-gap: 1.25rem;
            column-gap: 1.25rem;
    row-gap: 2.125rem; }
    @media screen and (min-width: 160.0625rem) {
      .cta__content {
        -webkit-column-gap: 0.78125vw;
           -moz-column-gap: 0.78125vw;
                column-gap: 0.78125vw;
        row-gap: 1.32812vw; } }
    @media screen and (max-width: 72.5rem) {
      .cta__content {
        grid-template-columns: repeat(2, 1fr); } }
    @media screen and (max-width: 43.75rem) {
      .cta__content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        gap: 1.25rem; } }
  .cta__column {
    grid-column: 1/4;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    gap: 0.9375rem; }
    @media screen and (max-width: 72.5rem) {
      .cta__column {
        grid-column: 1/2;
        grid-row: 1/2; } }
  .cta__title {
    color: var(--section-text-color-primary); }
  .cta__text {
    color: var(--section-text-color-secondary); }
  .cta__form {
    grid-column: 5/9;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    gap: 1.5625rem; }
    @media screen and (max-width: 72.5rem) {
      .cta__form {
        grid-column: 1/2;
        grid-row: 2/3; } }
    .cta__form .checkbox .text {
      color: var(--section-text-color-secondary); }
      @media screen and (max-width: 109.375rem) {
        .cta__form .checkbox .text {
          font-size: 0.75rem; } }
      .cta__form .checkbox .text a {
        position: relative;
        z-index: 1;
        color: var(--section-text-color-primary);
        text-decoration: underline; }
        @media (any-hover: hover) {
          .cta__form .checkbox .text a:hover {
            color: var(--accent-color); } }
  .cta__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: stretch;
        -ms-flex-pack: stretch;
            justify-content: stretch;
    gap: 1.25rem; }
    @media screen and (min-width: 160.0625rem) {
      .cta__row {
        gap: 0.78125vw; } }
    @media screen and (max-width: 43.75rem) {
      .cta__row {
        gap: 0.9375rem; } }
    @media screen and (max-width: 25rem) {
      .cta__row {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column; } }
    .cta__row .text-input {
      width: 100%;
      -ms-flex-negative: 1;
          flex-shrink: 1; }
      .cta__row .text-input input {
        width: 100%; }
    .cta__row .button {
      -ms-flex-negative: 0;
          flex-shrink: 0;
      white-space: nowrap; }
      @media screen and (max-width: 25rem) {
        .cta__row .button {
          width: 100%; } }
  .cta__image {
    position: relative;
    grid-column: 9/13;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    pointer-events: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    @media screen and (max-width: 72.5rem) {
      .cta__image {
        grid-column: 2/3;
        grid-row: 1/3; } }
    @media screen and (max-width: 43.75rem) {
      .cta__image {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content; } }
    .cta__image picture {
      position: absolute;
      bottom: -3.75rem;
      left: 1.25rem;
      right: 0;
      margin: auto;
      width: auto;
      height: calc(100% + 7.5rem + 3.9375rem); }
      @media screen and (min-width: 160.0625rem) {
        .cta__image picture {
          bottom: -2.34375vw;
          left: 0.78125vw;
          height: calc(100% + big(120) + big(63)); } }
      @media screen and (max-width: 72.5rem) {
        .cta__image picture {
          left: 0;
          height: calc(100% + 7.5rem + 2.875rem); } }
      @media screen and (max-width: 43.75rem) {
        .cta__image picture {
          position: relative;
          bottom: 0;
          width: auto;
          height: 16.625rem; } }
      .cta__image picture img {
        width: 100%;
        height: 100%;
        -o-object-fit: contain;
           object-fit: contain;
        -o-object-position: bottom;
           object-position: bottom; }
  .cta_second {
    padding: 0; }
    @media screen and (max-width: 66.875rem) {
      .cta_second {
        padding-top: 3.75rem; } }
    @media screen and (max-width: 39.375rem) {
      .cta_second {
        padding-top: 3.125rem; } }
    .cta_second .cta__content {
      grid-template-columns: repeat(2, 1fr);
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: start; }
      @media screen and (max-width: 66.875rem) {
        .cta_second .cta__content {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column;
          gap: 1.875rem; } }
      @media screen and (max-width: 39.375rem) {
        .cta_second .cta__content {
          gap: 1.25rem; } }
    .cta_second .cta__column {
      grid-column: 1/2; }
    .cta_second .cta__image {
      grid-column: 2/3;
      width: 100%; }
      .cta_second .cta__image img,
      .cta_second .cta__image picture {
        position: unset;
        width: 100%;
        height: 21rem;
        -o-object-fit: contain;
           object-fit: contain;
        -o-object-position: 50% 100%;
           object-position: 50% 100%; }
        @media screen and (min-width: 160.0625rem) {
          .cta_second .cta__image img,
          .cta_second .cta__image picture {
            height: 13.125vw; } }
        @media screen and (max-width: 109.375rem) {
          .cta_second .cta__image img,
          .cta_second .cta__image picture {
            height: 18.0625rem; } }
        @media screen and (max-width: 66.875rem) {
          .cta_second .cta__image img,
          .cta_second .cta__image picture {
            width: 100%;
            max-width: 25.25rem;
            height: auto; } }
    .cta_second .cta__form {
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: start;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: start; }
    .cta_second .cta__row {
      width: 100%; }
      @media screen and (max-width: 39.375rem) {
        .cta_second .cta__row {
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column; } }
      @media screen and (max-width: 39.375rem) {
        .cta_second .cta__row .button {
          width: 100%; } }
